import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { ErrorHandlerService } from 'app/shared/errors/error-handler.service';

import { API_CONFIG } from '../config/api.config';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const config = req.context.get(API_CONFIG);

  if (!config?.notifyError) {
    return next(req);
  }

  const errorHandlerService = inject(ErrorHandlerService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status >= 500) {
        errorHandlerService.handle(error);
      }

      return throwError(() => error);
    }),
  );
};
