import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import Bugsnag, { NotifiableError } from '@bugsnag/js';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  readonly isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: Record<string, unknown>) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  handle(error: NotifiableError, context?: Record<string, unknown>): void {
    Bugsnag.notify(error, (event) =>
      event.addMetadata('metadata', {
        context: { ...context },
        isPlatformBrowser: this.isBrowser,
        error,
      }),
    );
  }
}
