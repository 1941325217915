import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { AuthService } from 'app/core/services/auth.service';

/**
 * A guard that checks if the user is authenticated and redirects to the login page if they are not.
 * @param _ The route that is being activated. Not used in this guard.
 * @param state The current router state.
 * @returns True if the user is authenticated,
 * otherwise redirects to the login page with the current URL as a query parameter.
 */
export const authGuard: CanActivateFn = async (_, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const notFoundPageRoute = '/404';

  const isAuthenticated = await lastValueFrom(authService.isAuthenticated());

  if (isAuthenticated) {
    return true;
  }

  const { url } = state;

  if (url && url !== notFoundPageRoute) {
    return router.parseUrl(`/auth/login?url=${encodeURIComponent(url)}`);
  }

  return router.parseUrl('/auth/login');
};
