import { Routes } from '@angular/router';

import { authGuard } from './core/auth/guards/auth.guard';
import { ApiPortalComponent } from './modules/api-portal/api-portal.component';
import { NotFoundPage } from './shared/pages/not-found.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then((m) => m.SignupModule),
    canActivate: [authGuard],
  },
  {
    path: 'workspaces',
    loadChildren: () => import('./modules/workspace/workspace.module').then((m) => m.WorkspaceModule),
    canActivate: [authGuard],
  },
  {
    path: 'docs/dashpivot/api',
    component: ApiPortalComponent,
    children: [{ path: '**', component: ApiPortalComponent }],
    title: 'Dashpivot API Portal',
  },
  {
    path: '404',
    component: NotFoundPage,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
