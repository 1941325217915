import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterModule,
} from '@angular/router';
import { Observable, filter, map } from 'rxjs';

import { environment } from 'environments/environment';

import { LoadPageComponent } from './shared/pages/load-page.component';

@Component({
  selector: 'fs-root',
  template: `
    <router-outlet />
    <fs-load-page *ngIf="isNavigationInProgress$ | async" />
  `,
  standalone: true,
  imports: [RouterModule, CommonModule, LoadPageComponent],
})
export class AppComponent implements OnInit {
  private readonly router = inject(Router);
  public readonly appLoadedClass = 'app-loaded';
  public isNavigationInProgress$!: Observable<boolean>;

  ngOnInit() {
    this.loadPageProps();
    this.isNavigationInProgress$ = this.router.events.pipe(
      filter(this.isNavigationEvent),
      map((event) => event instanceof NavigationStart),
    );
  }

  private loadPageProps() {
    const loadedEnv = environment.environmentIdentifier;

    if (loadedEnv) {
      window.document.title += ` - ${loadedEnv}`;
    }
    window.document.body.classList.add(this.appLoadedClass);
  }

  private isNavigationEvent(event: Event) {
    return (
      event instanceof NavigationStart ||
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    );
  }
}
