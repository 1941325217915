import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';

import { environment } from 'environments/environment';

export function initializeBugsnag() {
  const apiKey = environment.bugsnagKey;

  // eslint-disable-next-line no-console -- we want to display the version in the browser console
  console.info(`${environment.environmentIdentifier} - flowsite web v${environment.version}`);

  const config = {
    apiKey,
    appVersion: environment.version,
    releaseStage: environment.environmentIdentifier || 'production',
  };

  Bugsnag.start(config);
  BugsnagPerformance.start(config);
}
